// const { default: Swal } = require("sweetalert2");

// console.log("load");

// function abc(){
//   console.log(4444);
// }


const Swal = require('sweetalert2');
const axios = require('axios');


// Get all the elements you want to show on scroll
const targets = document.querySelectorAll(".js-show-on-scroll");

// Set up a new observer
const observer = new IntersectionObserver(callback);

// Loop through each of the target
targets.forEach(function(target) {
  // Hide the element
 target.classList.add("opacity-0");
console.log("Hide the element");
  // Add the element to the watcher
  observer.observe(target);
});

// Callback for IntersectionObserver
function callback(entries) {
  entries.forEach(entry => {

    // Is the element in the viewport?
    if (entry.isIntersecting) {

      // Add the fadeIn class:
      console.log("Add the fadeIn class:");
      entry.target.classList.add("motion-safe:animate-fadeIn");
    //   entry.target.classList.remove("opacity-0");
    //   entry.target.classList.add("js-show-on-scroll");
    } else {
      console.log("remove class:");
      // Otherwise remove the fadein class
      entry.target.classList.remove("js-show-on-scroll");
    }
  });
};

// function submitForm(){
//   // var fname = document.getElementById("fname")[0].value;
//   // var lname = document.getElementsByName("laname")[0].value;
//   // var mail = document.getElementsByName("mail")[0].value;
//   // var subject = document.getElementsByName("subject")[0].value;
//   var message = "";
//   var phone = " ";
  
// //  window.open("mailto:example@gmail.com?subject=subject&body=Name:%20:name\n\n+Telephone:%20" + fname\ n\ n + message);
// window.open("mailto:example@gmail.com?subject=subject&body=Name:%20:name\n\n+Telephone:%20" + phone + "\n\n" + message);
// }

document.getElementById('submitEmail').addEventListener('click', function() {
  var fname = document.getElementById("fname").value;
  var lname = document.getElementById("lname").value;  
  var email =document.getElementById("email").value;
  var subject = document.getElementById("subject").value;
  var message = document.getElementById("message").value;

  
if(!fname){
  Swal.fire('First Name Filed is empty')
        return
    }

    if(!lname){
      Swal.fire('Last Name Filed is empty')

      return
    }
    if(!email)
    if(!subject){
      Swal.fire('Subject Filed is empty')

      return
    }

    if(!message){
      Swal.fire('Message Filed is empty')

      return
    }
    
    
   


  



  window.open("mailto:info@gohelth.com?subject="+ subject +"&body=Name:"+ fname+"\n\n+Last Name:"  + lname + "\n\n+Message:" + message);


});





      
  
    

